
import { Component, Vue } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import { IShipment, IShipmentStatusRequest } from "@/types/shipment";
import { IReceivedOrder } from "@/types/received_order";
import {
  IProductShipment,
  IProductShipmentView
} from "@/types/product_shipment";
import {
  IDuplicateProduct,
  IDuplicateProductRequest,
  IShipSlipRequest
} from "@/types/ship_slip";
import { ICustomer } from "@/types/customer";
import { IBranch } from "@/types/branch";

@Component({
  components: {}
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //shipment
  @Action("shipment/adminGet")
  public getshipment!: (id: number) => Promise<boolean>;

  @Action("shipment/adminChangeShipmentStatus")
  public changeShipmentStatus!: (data: {
    shipment_id: number;
    params: IShipmentStatusRequest;
  }) => Promise<boolean>;

  @Action("shipment/adminStoredShipmentNo")
  public storedShipmentNo!: (data: {
    shipment_id: number;
    shipment_date: string;
  }) => Promise<boolean>;

  @Action("shipment/adminUpdateShipmentNo")
  public updateShipmentNo!: (data: {
    shipment_id: number;
    shipment_no: string | null;
  }) => Promise<boolean>;

  @Action("shipment/adminUpdateInvoiceNo")
  public updateInvoiceNo!: (data: {
    shipment_id: number;
    invoice_no: string | null;
  }) => Promise<boolean>;

  @Getter("shipment/single")
  public shipment!: IShipment;

  @Mutation("shipment/clear")
  public clearShipment!: () => void;

  // received_order
  @Action("received_order/adminGet")
  public getReceivedOrder!: (received_order_id: number) => Promise<boolean>;

  @Action("received_order/adminReceivedOrderIsShipmentComplete")
  public adminReceivedOrderIsShipmentComplete!: (
    received_order_id: number
  ) => boolean;

  @Getter("received_order/single")
  public received_order!: IReceivedOrder;

  @Mutation("received_order/clear")
  public clearReceivedOrder!: () => void;

  //customer
  @Action("customer/adminGet")
  public getCustomer!: (customer_id: number) => Promise<boolean>;

  @Getter("customer/single")
  public customer!: ICustomer;

  @Mutation("customer/clear")
  public clearCustomer!: () => void;

  //branch
  @Action("branch/adminGet")
  public getBranch!: (branch_id: number) => Promise<boolean>;

  @Getter("branch/single")
  public branch!: IBranch;

  @Mutation("branch/clear")
  public clearBranch!: () => void;

  // pdf
  @Action("pdf/adminPDF")
  public getPDF!: (data: {
    order_no: string;
    customer_name: string;
    params: IShipSlipRequest;
  }) => Promise<boolean>;

  // ship_slip
  @Action("ship_slip/adminDuplicateCheck")
  public duplicateCheck!: (
    duplicate_product_params: IDuplicateProductRequest
  ) => Promise<boolean>;

  @Getter("ship_slip/duplicateProduct")
  public duplicate_product!: IDuplicateProduct[];

  // fields
  public shipment_id = 0;
  public product_shipment_id = 0;
  public shipment_no: string | null = "";
  public invoice_no: string | null = "";
  public params = {};
  public input_dialog = false;
  public submit_dialog = false;
  public caution_dialog = false;
  public shipment_no_dialog = false;
  public invoice_no_dialog = false;
  public valid_shipment_date = true;
  public valid_years_disp = false;
  public lazy = false;
  public dialog = false;
  public dialog_show = false;
  public selected = [];
  public menu = {
    print_date: false,
    shipment_date: false
  };
  public sheet_size_check = true;
  public valid = true;
  public row_length = 0;
  public duplicate_product_params: IDuplicateProductRequest = {
    shipment_id: 0
  };
  public shipment_status_params: IShipmentStatusRequest = {
    shipment_status: 0,
    shipment_date: "",
    shipment_no: "",
    invoice_no: ""
  };

  public slip_params: IShipSlipRequest = {
    shipment_id: 0,
    is_amount: false,
    is_amount_slip: false,
    sheet_size: "A4",
    sheet_type: "slip",
    is_company_stamp: true,
    print_date: "",
    delivery_slip_remark: "",
    invoice_remark: "",
    products: []
  };

  public headers = [
    { text: "商品名", value: "product_name", sortable: false },
    { text: "品番", value: "product_code", sortable: false },
    { text: "顧客品番", value: "customer_pn", sortable: false },
    { text: "ロットNo", value: "lot_no", sortable: false },
    { text: "備考", value: "memo", sortable: false },
    { text: "出荷数量", value: "quantity", sortable: false, align: "end" },
    {
      text: "仕入単価",
      value: "buying_unit_price",
      sortable: false,
      align: "end"
    },
    { text: "仕入単価合計", value: "amount", sortable: false, align: "end" },
    {
      text: "売単価",
      value: "selling_unit_price",
      sortable: false,
      align: "end"
    },
    {
      text: "売単価合計",
      value: "selling_unit_amount",
      sortable: false,
      align: "end"
    }
  ];

  //ルール設定
  public rules = {
    description_limit_length: [
      (value: string) =>
        this.inputCounter(value) <= 30 || "30文字以内で入力してください"
    ],

    remark_limit_length: [
      (value: string) =>
        this.inputCounter(value) <= 174 || "174文字以内で入力してください"
    ],
    shipment_date: [(v: string) => !!v || "出荷日は必須です"],
    product_limit_length: [
      (value: string) =>
        this.inputCounter(value) <= 60 || "60文字以内で入力してください"
    ]
  };

  public async created() {
    this.shipment_id = Number(this.$route.params.shipment_id);
    this.clearShipment();
    this.clearReceivedOrder();
    this.clearCustomer();
    this.clearBranch();

    await this.getshipment(this.shipment_id);
    await this.getReceivedOrder(this.shipment.received_order_id);
    await this.getCustomerInfomation();

    this.valid_years_disp = this.diffMonth(this.shipment.shipment_date) > 12;
    this.shipment_no = this.shipment.shipment_no;
    this.invoice_no = this.shipment.invoice_no;
    this.shipment_status_params.shipment_no = this.shipment.shipment_no;
    this.shipment_status_params.invoice_no = this.shipment.invoice_no;
  }

  // 受注者(顧客)情報をもってくる
  public async getCustomerInfomation() {
    await this.getCustomer(this.received_order.customer_id);
    if (this.received_order.branch_id) {
      await this.getBranch(this.received_order.branch_id);
    }
  }

  //出荷商品表示用配列生成
  get setValue() {
    return this.shipment.product_shipment.map(el => ({
      product_shipment_id: el.id,
      product_name: el.product_name,
      product_code: el.product_code,
      customer_pn: el.customer_pn,
      lot_no: el.lot_no,
      memo: el.memo,
      selling_unit_price: Number(el.selling_unit_price),
      amount: Number(el.amount),
      buying_unit_price: Number(el.buying_unit_price),
      selling_unit_amount: Number(el.selling_unit_amount),
      quantity: Number(el.quantity)
    }));
  }

  //売単価合計計算
  public amount_count(val: Array<IProductShipment>) {
    let amount_num = 0;
    val.forEach(element => {
      amount_num += Number(element.selling_unit_amount);
    });

    return amount_num;
  }

  // 確定ボタンが押された際の挙動
  public async submit() {
    this.submit_dialog = false;
    this.shipment_status_params.shipment_status =
      Vue.prototype.$shipmentStatus.shipped;
    // 出荷ステータス変更
    await this.changeShipmentStatus({
      shipment_id: this.shipment.id,
      params: this.shipment_status_params
    });
    // 受注ステータス変更
    await this.adminReceivedOrderIsShipmentComplete(
      this.shipment.received_order_id
    );
    this.$router.go(0);
  }

  // 行をクリックした際の挙動
  public rowClick(setValue: IProductShipmentView) {
    if (
      this.shipment.shipment_status != Vue.prototype.$shipmentStatus.shipped ||
      this.valid_years_disp
    ) {
      return;
    }

    this.caution_dialog = true;
    this.product_shipment_id = setValue.product_shipment_id;
  }

  // 出荷ボタンをクリックした際の挙動
  public shipmentBtn() {
    this.input_dialog = true;

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  // 数量改変履歴に遷移
  public changeQuantityHistory() {
    this.$router.push(
      `/admin/shipment/${this.shipment_id}/product_shipment/${this.product_shipment_id}/quantity_history/change`
    );
  }

  // 納品書番号更新
  public async shipmentNoSubmit() {
    this.shipment_no_dialog = false;

    await this.updateShipmentNo({
      shipment_id: this.shipment_id,
      shipment_no: this.shipment_no
    });

    this.$router.go(0);
  }

  // 請求書番号更新
  public async invoiceNoSubmit() {
    this.invoice_no_dialog = false;

    await this.updateInvoiceNo({
      shipment_id: this.shipment_id,
      invoice_no: this.invoice_no
    });

    this.$router.go(0);
  }

  // これ以下は納品書関係
  // printerアイコンをクリックしたら印刷する商品をセットする
  public async print_set() {
    this.slip_params.products.splice(0);
    this.duplicate_product_params.shipment_id = this.slip_params.shipment_id =
      this.shipment_id;
    await this.duplicateCheck(this.duplicate_product_params);

    this.duplicate_product.forEach(selling => {
      selling.sellings.forEach(el => {
        this.slip_params.products.push({
          product_name: selling.customer_pn
            ? selling.product_name +
              " / " +
              selling.product_code +
              " / " +
              selling.customer_pn
            : selling.product_name + " / " + selling.product_code,
          product_id: selling.product_id,
          product_unit: selling.product_unit,
          quantity_sum: el.quantity_sum,
          selling_unit_price: el.selling_unit_price,
          description: "",
          product_rows: []
        });
      });
    });
    this.sheet_size_check = true;
    this.valid = true;

    this.rowCount();

    this.dialog = true;
    this.dialog_show = true;

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  public rowCount() {
    let length_sum = 0;
    length_sum += this.slip_params.products.length;
    this.slip_params.products.forEach(product => {
      length_sum += product.product_rows.length;
    });
    this.row_length = length_sum;

    if (length_sum > 6) {
      this.slip_params.sheet_size = "A4";
      this.sheet_size_check = false;
    } else {
      this.sheet_size_check = true;
    }
  }

  public print_close() {
    this.dialog = false;
    this.dialog_show = false;
    this.slip_params.products.splice(0);
    this.sheet_size_check = true;
    this.valid = true;
  }

  public async getSlip() {
    let result = false;

    result = await this.storedShipmentNo({
      shipment_id: this.shipment_id,
      shipment_date: this.shipment.shipment_date
    });

    if (result) {
      result = await this.getPDF({
        order_no: this.received_order.order_no,
        customer_name: this.customer.short_name,
        params: this.slip_params
      });
    }

    if (result) {
      await this.getshipment(this.shipment_id);
    }

    this.print_close();
  }

  public addRowBtn(index: number) {
    this.slip_params.products[index].product_rows.push({
      product_name: "",
      description: "",
      is_print: true
    });
  }
  public deleteRowBtn(product_index: number, row_index: number) {
    this.slip_params.products[product_index].product_rows.splice(row_index, 1);
  }
}
